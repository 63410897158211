import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
const NLPSolution = () => {
  return (
    <>
      <Helmet>
        <title>Dịch máy Anh-Việt</title>
        <meta
          name="description"
          content="Triển khai các mô hình dịch máy anh việt, seq2seq translation"
        />
      </Helmet>
      <Intro
        gray
        title="Phát triển các giải pháp về xử lý ngôn ngữ tự nhiên"
        subtitle="Chúng tôi cung các cho khách hàng các dịch vụ da đạng giúp giải
                quyết nhanh và nâng cao chất lượng sản phẩm của bạn"
        img={require("../images/land.svg").default}
        imgHeight="400px"
      />
      <Hero
        type="nlp_solution"
        title="Các Giải Pháp"
        subtitle="Chúng tôi liên tục cải thiện giải pháp để đáp ứng nhu cầu của khách hàng"
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature gray />
      {/* <Price /> */}
      {/* <Client gray /> */}
    </>
  );
};

export default NLPSolution;
