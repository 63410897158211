import styled from "styled-components";

export const ClientContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
  
`;
export const ClientInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
`;
export const ClientHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
`;
export const ClientTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
`;
export const ClientSubtitle = styled.div``;
export const ClientLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ClientLogo = styled.img`
  height: 70px;
  padding: 0 40px;
  background: #fff;
  border-radius: 5px;
`;
