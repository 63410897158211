import React from "react";
import { DropdownMenu, DropdownItem } from "./Dropdown.elements";
import { ItemsGP, ItemsSP } from "./MenuItems";

const Dropdown = ({ type, onClick }) => {
  const Items = type === "sp" ? ItemsSP : ItemsGP;
  return (
    <>
      <DropdownMenu>
        {Items.map((item, index) => {
          return (
            <DropdownItem
              key={index}
              to={item.path}
              onClick={onClick}
              className="dropdownItem"
            >
              {item.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </>
  );
};

export default Dropdown;
