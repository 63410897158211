import React from "react";
import {
  DeploymentContainer,
  DeploymentInner,
  Heading,
  Title,
  Process,
  Left,
  Img,
  Right,
  Step,
  Icon,
  Content,
  StepTitle,
  StepDesc,
} from "./Deployment.elements";

import { Items } from "./ItemData";
const Deployment = ({ type, img }) => {
  const items = Items[type];

  return (
    <>
      <DeploymentContainer>
        <DeploymentInner>
          <Heading>
            <Title>Quy Trình Nhận Dạng</Title>
          </Heading>
          <Process>
            <Left>
              <Img src={img} />
            </Left>
            <Right>
              {items.map((item, index) => {
                return (
                  <Step>
                    <Icon src={item.icon}></Icon>
                    <Content>
                      <StepTitle>{item.title}</StepTitle>
                      <StepDesc>{item.desc}</StepDesc>
                    </Content>
                  </Step>
                );
              })}
            </Right>
          </Process>
        </DeploymentInner>
      </DeploymentContainer>
    </>
  );
};

export default Deployment;
