import React from "react";
import { FaCcVisa } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Items } from "./ItemData";
import {
  HeroContainer,
  HeroInner,
  HeroHeading,
  HeroTitle,
  HeroSubtitle,
  HeroFeatures,
  Feature,
  FeatureInner,
  FeatureIcon,
  FeatureTitle,
  FeatureDesc,
  IconSvg,
} from "./Hero.elements";
const Hero = ({ gray, type, filter, title, subtitle }) => {
  const products = Items[type ? type : "allproducts"];
  const heroTitle = title ? title : "Các sản phẩm";
  const heroSubtitle = subtitle
    ? subtitle
    : "Chúng tôi liên tục cải tiến sản phẩm để đáp ứng nhu cầu của khách hàng";

  return (
    <IconContext.Provider value={{ size: "40px", color: "black" }}>
      <HeroContainer gray={gray}>
        <HeroInner>
          <HeroHeading>
            <HeroTitle>{heroTitle}</HeroTitle>
            <HeroSubtitle>{heroSubtitle}</HeroSubtitle>
          </HeroHeading>
          <HeroFeatures>
            {products.map((item, index) => {
              return (
                <Feature key={index}>
                  <FeatureInner>
                    <IconSvg src={item.icon2} filter={filter} />
                    {/* <FeatureIcon>{item.icon}</FeatureIcon> */}
                    <FeatureTitle>{item.title}</FeatureTitle>
                    <FeatureDesc>{item.desc}</FeatureDesc>
                  </FeatureInner>
                </Feature>
              );
            })}
          </HeroFeatures>
        </HeroInner>
      </HeroContainer>
    </IconContext.Provider>
  );
};

export default Hero;
