import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
import Deployment from "../components/Deployment";
import ContactForm from "../components/ContactForm";
const NICProducts = () => {
  return (
    <>
      <Helmet>
        <title>OCR - Rút trích thông tin giấy tờ tùy thân</title>
        <meta
          name="description"
          content="OCR giấy tờ tùy thân, căn cước công dân, giấy phép lái xe, rút trích thông tin giấy tờ tùy thân"
        />
      </Helmet>
      <Intro
        gray
        title="Nhận dạng giấy tờ tùy thân"
        subtitle="Hỗ trợ nhận dạng chứng minh nhân dân căn cước công dân, giấy phép lái xe, hộ chiếu. Giúp tăng tốc quá trình định dang khách hàng và cải thiện trải nghiệm người dùng"
        img={require("../images/cmnd_bg.jpg").default}
        imgHeight="600px"
      />
      <Deployment img={require("../images/cmnd.jpg").default} type="cmnd" />
      <Hero
        type="cmnd"
        gray
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature />
      {/* <Price /> */}
      <ContactForm gray />
    </>
  );
};

export default NICProducts;
