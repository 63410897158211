import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

import "./App.css";
import ScrollToTop from "./components/ScrollToTop";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Prices from "./pages/Prices";
import Integrate from "./pages/Integrate";
import NICProducts from "./pages/NICProducts";
import ViOCRProducts from "./pages/ViOCRProducts";
import FaceMatchingProducts from "./pages/FaceMatchingProducts";
import NLPSolution from "./pages/NLPSolution";
import ImageSolution from "./pages/ImageSolution";
import SpeechSolution from "./pages/SpeechSolution";
import DigitalizeSolution from "./pages/DigitalizeSolution";

const trackPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const initGa = (history) => {
  ReactGA.initialize("UA-159310602-2", {
    debug: false,
  });
  trackPageView(history.location);
  history.listen(trackPageView);
};

const history = createBrowserHistory();
initGa(history);

function App() {
  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={Products} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/price" exact component={Prices} />
          <Route path="/integrate" exact component={Integrate} />
          <Route path="/nic" exact component={NICProducts} />
          <Route path="/viocr" exact component={ViOCRProducts} />
          <Route path="/face" exact component={FaceMatchingProducts} />
          <Route path="/nlp_solution" exact component={NLPSolution} />
          <Route path="/image_solution" exact component={ImageSolution} />
          <Route path="/speech_solution" exact component={SpeechSolution} />
          <Route
            path="/digitalize_solution"
            exact
            component={DigitalizeSolution}
          />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
