import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
import Code from "../components/Code";
const Integrate = () => {
  return (
    <>
      <Helmet>
        <title>VOCR - Tích hợp</title>
        <meta
          name="description"
          content="Tích hợp các mô hình số hóa nhanh chóng, đơn giản"
        />
      </Helmet>
      <Intro
        gray
        title="Tích hợp nhanh chóng thông qua API, hỗ trợ tất cả các nền tảng"
        subtitle="Chúng tôi cung cấp cho bạn các lựa chọn phù hợp với quy mô kinh doanh của doanh nghiệp"
        img={require("../images/connected_world.svg").default}
        imgHeight="350px"
      />
      <Code />
      <Feature gray />
      <Hero />
      <Price gray />

      {/* <Client /> */}
    </>
  );
};

export default Integrate;
