import React from "react";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
const Products = () => {
  return (
    <>
      <Intro
        gray
        title="Hỗ trợ sản phẩm của bạn bằng các dịch vụ AI tiên tiến nhất"
        subtitle="Chúng tôi cung các cho khách hàng các dịch vụ da đạng giúp giải
                quyết nhanh và nâng cao chất lượng sản phẩm của bạn"
        img={require("../images/undraw_upgrade_06a0.svg").default}
      />
      <Hero />
      <Feature gray />
      <Price />
      <Client gray />
    </>
  );
};

export default Products;
