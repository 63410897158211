import styled from "styled-components";
import { Link } from "react-router-dom";
export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  position: absolute;
  top: 61px;
  list-style: none;
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 20%), 0 0 0.05rem rgb(0 0 0 / 20%);
  /* border-radius: 5px; */

  @media screen and (max-width: 960px) {
    position: relative;
    box-shadow: none;
    top: 0;
    left: 50px;
    font-size: 15px;
  }
`;

export const DropdownItem = styled(Link)`
  color: #000;
  padding: 15px 20px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #42a5f5;
  }
`;
