export const ItemsSP = [
  { name: "Giấy tờ tùy thân", path: "/nic" },
  { name: "Nhận dạng Tiếng Việt", path: "/viocr" },
  { name: "So sánh khuôn mặt", path: "/face" },
];

export const ItemsGP = [
  { name: "Xử lý ngôn ngữ", path: "/nlp_solution" },
  { name: "Nhận dạng hình ảnh", path: "/image_solution" },
  { name: "Nhận dạng giọng nói", path: "/speech_solution" },
  { name: "Số hóa văn bản", path: "/digitalize_solution" },
];
