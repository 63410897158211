import React from "react";
import {
  ClientContainer,
  ClientInner,
  ClientHeading,
  ClientTitle,
  ClientSubtitle,
  ClientLogos,
  ClientLogo,
} from "./Client.elements";
const Client = ({gray}) => {
  return (
    <>
      <ClientContainer gray={gray}>
        <ClientInner>
          <ClientHeading>
            <ClientTitle>Khách Hàng Của Chúng Tôi</ClientTitle>
            <ClientSubtitle>Sự tận tâm là điều cốt lõi</ClientSubtitle>
          </ClientHeading>

          <ClientLogos>
            <ClientLogo
              src={require("../../images/vmg_client.png").default}
            ></ClientLogo>
          </ClientLogos>
        </ClientInner>
      </ClientContainer>
    </>
  );
};

export default Client;
