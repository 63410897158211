import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
import Deployment from "../components/Deployment";
import ContactForm from "../components/ContactForm";
const FaceMatchingProducts = () => {
  return (
    <>
      <Helmet>
        <title>OCR - So sánh, tìm kiếm khuôn mặt</title>
        <meta
          name="description"
          content="Ekyc, so sánh tìm kiếm khuôn mặt, xác thực tài khoản"
        />
      </Helmet>
      <Intro
        gray
        title="So sánh khuôn mặt"
        subtitle="Ứng dụng trong quá trình định danh khách hàng, giúp xác thực tài khoản nhanh chóng. Hỗ trợ tìm kiếm khuôn mặt trong cơ sở dữ liệu"
        img={require("../images/face_search_bg.jpg").default}
        imgHeight="600px"
      />
      <Deployment
        img={require("../images/face_verfication.jpg").default}
        type="face"
      />
      <Hero
        type="face"
        gray
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature />
      {/* <Price /> */}
      <ContactForm gray />
    </>
  );
};

export default FaceMatchingProducts;
