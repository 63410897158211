import styled from "styled-components";

export const HeroContainer = styled.div`
  background: $fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
`;
export const HeroInner = styled.div`
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`;
export const HeroHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0;
`;
export const HeroTitle = styled.h2`
  font-size: 30px;
`;
export const HeroSubtitle = styled.div`
  margin-top: 15px;
  text-align: center;
`;
export const HeroFeatures = styled.div`
  /* display: flex;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  } */
  /* --n: 3;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(300px, 100% / var(--n)), 1fr)
  ); */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
export const Feature = styled.div`
  display: flex;
  max-width: 390px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 20px;
  cursor: pointer;
  /* &:hover {
    color: #42a5f5;
  } */
  /* max-width: 400px; */
`;

export const FeatureInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
`;

export const FeatureIcon = styled.div``;
export const IconSvg = styled.img`
  width: 60px;
  height: 60px;

  filter: ${({ filter }) => filter};
`;
export const FeatureTitle = styled.div`
  font-size: 18px;
  margin: 10px 0;
  text-align: center;
  font-weight: 500;
`;
export const FeatureDesc = styled.div`
  text-align: center;
`;
