import React from "react";
import { Helmet } from "react-helmet";

import Board from "../components/Board";
import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import ContactForm from "../components/ContactForm";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>OCR - VietOCR nhận dạng tiếng việt, chữ viết tay</title>
        <meta
          name="description"
          content="Rút trích thông tin chứng minh thư, căn cước, nhận dạng chữ viết tay, nhận dạng kí tự quang học, OCR"
        />
      </Helmet>
      <Board />
      <Hero />
      <Feature gray />
      <Price />
      <ContactForm />

      {/* <Client gray /> */}
    </>
  );
};

export default Home;
