import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  padding: 5px;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;

const Table = ({ data }) => {
  const tables = data;

  return (
    <Styles>
      {tables &&
        tables.map((table, index) => {
          return (
            <table key={index}>
              <thead>
                <tr>
                  {table.header.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {table.data.map((row, index) => {
                  return (
                    <tr key={index}>
                      {row.map((col, index) => {
                        return <td key={index}>{col}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
    </Styles>
  );
};

export default Table;
