import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
import Deployment from "../components/Deployment";
import ContactForm from "../components/ContactForm";
const ViOCRProducts = () => {
  return (
    <>
      <Helmet>
        <title>OCR - Nhận dạng tiếng việt</title>
        <meta
          name="description"
          content="Nhận dạng chữ viết tay, nhận dạng kí tự quang học"
        />
      </Helmet>
      <Intro
        gray
        title="Nhận dạng Tiếng Việt"
        subtitle="Chúng ta cung cấp các dịch vụ nhận dạng tiếng việt cho các văn bản bất kì với độ chính xác cao. Giúp hỗ trợ quá trình lưu trữ và tìm kiếm văn bản dựa theo từ khóa nhanh chóng, tiện lợi"
        img={require("../images/ocr_bg.jpg").default}
        imgHeight="600px"
      />
      <Deployment img={require("../images/ocr.jpg").default} type="ocr" />
      <Hero
        type="ocr"
        gray
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature />
      {/* <Price /> */}
      <ContactForm gray />
    </>
  );
};

export default ViOCRProducts;
