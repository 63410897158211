import React, { useState } from "react";
import emailjs from "emailjs-com";

import {
  FormContainer,
  FormInner,
  FormWrapper,
  Row,
  PersonalInput,
  MessageInput,
  SubmitBtn,
  FormHeading,
  FormTitle,
  FormSubtitle,
  FormHotline,
} from "./ContactForm.elements";

const ContactForm = () => {
  const [btnName, setBtnName] = useState("Gửi");

  const handleSubmit = (event) => {
    event.preventDefault();

    const userId = "user_TNtRrxebgxiqqsHFkzGPT";
    const serviceID = "default_service";
    const templateID = "template_4hkxbmc";

    emailjs.sendForm(serviceID, templateID, event.target, userId).then(
      () => {
        // btn.value = "Send Email";
        // alert("Sent!");
        setBtnName("Gửi thành công");
      },
      (err) => {
        // btn.value = "Send Email";
        // alert(JSON.stringify(err));
        setBtnName("Gửi thất bại");
      }
    );
  };

  return (
    <>
      <FormContainer>
        <FormInner>
          <FormWrapper onSubmit={handleSubmit}>
            <FormHeading>
              <FormTitle>Liên Hệ Ngay Với Chúng Tôi</FormTitle>
              <FormSubtitle>
                Chúng tôi luôn sẵn sàng lắng nghe các yêu cầu từ khách hàng
              </FormSubtitle>
              <FormHotline>Số hotline 0378.050.314</FormHotline>
            </FormHeading>

            <Row>
              <PersonalInput
                type="text"
                name="ho_va_ten"
                placeholder="Họ và tên"
              />
              <PersonalInput
                type="text"
                name="ten_cong_ty"
                placeholder="Tên công ty"
              />
            </Row>
            <Row>
              <PersonalInput
                type="email"
                name="email"
                placeholder="Địa chỉ email"
                required
              />
              <PersonalInput
                type="tel"
                name="sdt"
                placeholder="Số điện thoại"
                required
              />
            </Row>
            <MessageInput placeholder="Nội dung liên hệ" name="msg" />
            <SubmitBtn type="submit" value={btnName} />
          </FormWrapper>
        </FormInner>
      </FormContainer>
    </>
  );
};

export default ContactForm;
