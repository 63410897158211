import React from "react";
import { FaFire } from "react-icons/fa";
import { BsXDiamondFill } from "react-icons/bs";
import { GiCrystalize } from "react-icons/gi";
import { IconContext } from "react-icons";

import {
  PriceContainer,
  PriceInner,
  PriceHeading,
  PriceTitle,
  PriceSubtitle,
  PriceWrap,
  PriceCard,
  Icon,
  PricePack,
  PriceValue,
  PriceUnit,
  PriceFeatures,
  Feature,
  PriceBtn,
} from "./Price.elements";
const Price = ({ gray }) => {
  return (
    <IconContext.Provider value={{ color: "#42A5F5", size: 50 }}>
      <PriceContainer gray={gray}>
        <PriceInner>
          <PriceHeading>
            <PriceTitle>Bảng Giá</PriceTitle>
            <PriceSubtitle>Giá trị tốt nhất cho khách hàng</PriceSubtitle>
          </PriceHeading>
          <PriceWrap>
            <PriceCard>
              <Icon>
                <FaFire />
              </Icon>
              <PricePack>Miễn Phí</PricePack>
              <PriceValue>0 Đồng</PriceValue>
              <PriceUnit>mỗi lượt gọi</PriceUnit>
              <PriceFeatures>
                <Feature>1000 lần nhận dạng Tiếng Việt</Feature>
                <Feature>50 lần gọi mỗi API khác</Feature>
                <Feature>Hỗ trợ khi gặp lỗi</Feature>
              </PriceFeatures>
              <PriceBtn outline={1} to="/contact">
                Liên Hệ
              </PriceBtn>
            </PriceCard>
            <PriceCard>
              <Icon>
                <BsXDiamondFill />
              </Icon>
              <PricePack>Doanh Nghiệp</PricePack>
              <PriceValue>600 Đồng</PriceValue>
              <PriceUnit>mỗi lượt gọi</PriceUnit>
              <PriceFeatures>
                <Feature>Không giới hạn lần gọi</Feature>
                <Feature>Hỗ trợ theo yêu cầu</Feature>
                <Feature>Hỗ trợ 24/7</Feature>
              </PriceFeatures>
              <PriceBtn to="/contact">Liên Hệ</PriceBtn>
            </PriceCard>
            <PriceCard>
              <Icon>
                <GiCrystalize />
              </Icon>
              <PricePack>Theo Yêu Cầu</PricePack>
              <PriceValue>Thỏa thuận</PriceValue>
              <PriceUnit>mỗi lượt gọi</PriceUnit>
              <PriceFeatures>
                <Feature>Không giới hạn lần gọi</Feature>
                <Feature>Phát triển theo yêu cầu</Feature>
                <Feature>Hỗ trợ 24/7</Feature>
              </PriceFeatures>
              <PriceBtn outline={1} to="/contact">
                Liên Hệ
              </PriceBtn>
            </PriceCard>
          </PriceWrap>
        </PriceInner>
      </PriceContainer>
    </IconContext.Provider>
  );
};

export default Price;
