import styled from "styled-components";

export const DeploymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeploymentInner = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
`;
export const Heading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;
export const Process = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;

  margin-bottom: 50px;
  margin-top: 50px;

  @media screen and (max-width: 960px) {
    margin-right: 0;
  }
`;
export const Img = styled.img`
  max-width: 300px;
`;
export const Right = styled.div`
  /* border-radius: 5px; */
`;
export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  /* padding: 20px 50px; */
  padding: 20px 50px 20px 50px;
`;
export const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  filter: invert(20%) sepia(10%) saturate(3322%) hue-rotate(196deg)
    brightness(95%) contrast(92%);
`;
export const Content = styled.div``;
export const StepTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const StepDesc = styled.div`
  max-width: 350px;
`;
