export const item_face = [
  {
    title: "Thu thập khuôn mặt",
    desc: "Dữ liệu khuôn mặt với góc nhìn khác nhau của mỗi người được thu thập từ nhiều nguồn khác nhau",
    icon: require("../../images/data-collection (1).svg").default,
  },
  {
    title: "Huấn luyện mô hình so sánh khuôn mặt",
    desc: "Chúng tôi huấn luyện mô hình AI tiên tiến để phân loại khuôn mặt dựa trên dự liệu có sẵn và được bổ sung theo yêu cầu của khách hàng để cho kết quả chính xác nhất",
    icon: require("../../images/machine-learning.svg").default,
  },
  {
    title: "Đánh giá kết quả",
    desc: "Kết quả dự đoán được kiểm tra bởi khách hàng để đảm bảo các tiêu chí khi sử dụng cho thực tiễn",
    icon: require("../../images/bar-chart.svg").default,
  },
  {
    title: "Triển khai cho khách hàng",
    desc: "Hỗ trợ khách hàng tích hợp vào hệ thống hiện có theo dạng API hoặc Dedicated Service",
    icon: require("../../images/startup.svg").default,
  },
];

export const item_ocr = [
  {
    title: "Thu thập văn bản",
    desc: "Dữ liệu được cung cấp bởi khách hàng đối với các loại văn bản không phổ biến",
    icon: require("../../images/data-collection (1).svg").default,
  },
  {
    title: "Huấn luyện mô hình OCR",
    desc: "Chúng tôi huấn luyện mô hình OCR gồm 3 bước: tách văn bản khỏi nền, xác định các trường cần thiết theo yêu cầu của khách hàng, nhận dạng từng trường được bóc tách",
    icon: require("../../images/machine-learning.svg").default,
  },
  {
    title: "Đánh giá kết quả",
    desc: "Kết quả dự đoán được kiểm tra bởi khách hàng để đảm bảo các tiêu chí khi sử dụng cho thực tiễn",
    icon: require("../../images/bar-chart.svg").default,
  },
  {
    title: "Triển khai cho khách hàng",
    desc: "Hỗ trợ khách hàng tích hợp vào hệ thống hiện có theo dạng API hoặc Dedicated Service",
    icon: require("../../images/startup.svg").default,
  },
];

export const item_cmnd = [
  {
    title: "Thu thập giấy tờ tùy thân",
    desc: "Chúng tôi cung cấp sẵn dữ liệu của các loại giấy tờ tùy thân phổ biến như chứng minh thư, giấy phép lái xe. Những yêu cầu đặc biệt, khách hàng cần cung cấp dữ liệu",
    icon: require("../../images/data-collection (1).svg").default,
  },
  {
    title: "Huấn luyện mô hình số hóa",
    desc: "Chúng tôi sử dụng mô hình AI tiên tiến để huấn luyện trên tập dữ liệu đã có và điều chỉnh dựa theo bộ dữ liệu của khách hàng",
    icon: require("../../images/machine-learning.svg").default,
  },
  {
    title: "Đánh giá kết quả",
    desc: "Kết quả dự đoán được kiểm tra bởi khách hàng để đảm bảo các tiêu chí khi sử dụng cho thực tiễn",
    icon: require("../../images/bar-chart.svg").default,
  },
  {
    title: "Triển khai cho khách hàng",
    desc: "Hỗ trợ khách hàng tích hợp vào hệ thống hiện có theo dạng API hoặc Dedicated Service",
    icon: require("../../images/startup.svg").default,
  },
];

export const Items = {
  cmnd: item_cmnd,
  ocr: item_ocr,
  face: item_face,
};
