import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
import ContactForm from "../components/ContactForm";
const DigitalizeSolution = () => {
  return (
    <>
      <Helmet>
        <title>OCR - Công nghệ số hóa</title>
        <meta
          name="description"
          content="Chuyển giao và phát triển công nghệ số hóa"
        />
      </Helmet>
      <Intro
        gray
        title="Chuyển giao và phát triển các mô hình nhận dạng văn bản tiên tiến"
        subtitle="Chúng tôi phát triển, hỗ trợ và chuyển giao công nghệ số hóa các văn bản cho doanh nghiệp. Giúp các doanh nghiệp chủ động trong quá trình hội nhập"
        img={require("../images/banner_scan-01.png").default}
        imgHeight="350px"
      />
      <Hero
        type="digitalize_solution"
        title="Các Giải Pháp"
        subtitle="Chúng tôi liên tục cải thiện giải pháp để đáp ứng nhu cầu của khách hàng"
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature gray />
      {/* <Price /> */}
      {/* <Client gray /> */}
      {/* <ContactForm gray /> */}
    </>
  );
};

export default DigitalizeSolution;
