import React from "react";

import {
  CodeContainer,
  CodeInner,
  CodeHeading,
  CodeText,
  CodeWrapper,
  Heading,
  Title,
  Subtitle,
} from "./Code.elements";
const Code = () => {
  const text = `
  import requests

  url = 'https://services.vocr.vn/path'
  files = {'file': open(filename, 'rb')}
  response = requests.post(url, files=files)
  return response.json()
  `;
  return (
    <>
      <CodeContainer>
        <CodeInner>
          <Heading>
            <Title>Tích Hợp Đơn Giản</Title>
            <Subtitle>Kết nối với dịch vụ thông qua API</Subtitle>
          </Heading>
          <CodeWrapper>
            <CodeHeading>Python</CodeHeading>
            <CodeText>{text}</CodeText>
          </CodeWrapper>
        </CodeInner>
      </CodeContainer>
    </>
  );
};

export default Code;
