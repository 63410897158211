import React from "react";
import { IconContext } from "react-icons";
import { Items } from "./ItemData";
import {
  FeatureContainer,
  FeatureInner,
  Features,
  FeatureTitle,
  FeatureLeft,
  FeatureIcon,
  FeatureHeading,
  FeatureDesc,
  FeatureRight,
  FeatureImg,
  FeatureWrap,
  FeatureSubtitle,
} from "./Feature.elements";

import { BiVector } from "react-icons/bi";
const Feature = ({ gray }) => {
  return (
    <IconContext.Provider value={{ size: "50px", color: "#18191F" }}>
      <FeatureContainer gray={gray}>
        <FeatureInner>
          <FeatureLeft>
            <FeatureTitle>Tích Hợp Nhanh Chóng</FeatureTitle>
            <Features>
              {Items.map((item, index) => {
                return (
                  <FeatureWrap key={index}>
                    <FeatureIcon>{item.icon}</FeatureIcon>
                    <FeatureHeading>
                      <FeatureSubtitle>{item.title}</FeatureSubtitle>
                      <FeatureDesc>{item.desc}</FeatureDesc>
                    </FeatureHeading>
                  </FeatureWrap>
                );
              })}
            </Features>
          </FeatureLeft>
          <FeatureRight>
            <FeatureImg
              src={require("../../images/api.png").default}
            ></FeatureImg>
          </FeatureRight>
        </FeatureInner>
      </FeatureContainer>
    </IconContext.Provider>
  );
};

export default Feature;
