import styled from "styled-components";
import { Link } from "react-router-dom";
export const FooterContainer = styled.div`
  background: #fff;
  color: #000;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d9dbe1;
`;
export const FooterInner = styled.div`
  display: flex;
  max-width: 1300px;
  width: 100%;
  margin: 50px 0;
  justify-content: space-between;
  text-align: left;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    /* justify-content: start; */
  }
`;
export const Column = styled.div`
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 300px;
  }
`;
export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
`;
export const LogoIcon = styled.div`
  font-size: 50px;
  margin-right: 20px;
`;
export const LogoName = styled.div`
  font-size: 30px;
  font-weight: 700;
`;
export const FooterContact = styled.div``;

export const ContactItem = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0;
`;
export const FooterItem = styled(Link)`
  text-decoration: none;
  color: #000;
  margin-top: 10px;
  width: 100%;
  &:hover {
    color: #303667;
  }
`;
export const ContactIcon = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

export const CopyRight = styled.div``;
