import styled from "styled-components";

export const CodeContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
  /* width: ; */
`;
export const CodeInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
`;
export const CodeHeading = styled.div`
  padding: 10px 20px;
  background: #303667;
  border-radius: 0 5px 0 0;
  color: #fff;
  font-weight: 700;
`;
export const CodeText = styled.div`
  white-space: pre-line;
  padding-left: 20px;
  padding-bottom: 20px;
  line-height: 20px;
  /* border-radius: 0 0 5px 5px; */
  & code {
    border-radius: 0 0 5px 5px;
    padding: 0 10px !important;
  }
`;

export const CodeWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 30%), 0 0 0.05rem rgb(0 0 0 / 20%);
  border-left: 4px solid #42a5f5;
  border-radius: 5px;
  /* border-radius: 10px; */
`;

export const Heading = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;
export const Subtitle = styled.div`
  margin-top: 10px;
`;
