import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
const SpeechSolution = () => {
  return (
    <>
      <Helmet>
        <title>Nhận dạng tiếng nói</title>
        <meta
          name="description"
          content="Text2speech, speech2text, tổng hợp tiếng nói, nhận dạng âm thanh"
        />
      </Helmet>
      <Intro
        gray
        title="Triển khai các phương pháp nhận dạng, phân tích giọng nói"
        subtitle="Cung cấp các dịch vụ liên quan đến tiếng nói như phân tích và tổng hợp tiếng nói, phân loại âm thanh"
        img={require("../images/speech.jpg").default}
        imgHeight="350px"
      />
      <Hero
        type="speech_solution"
        title="Các Giải Pháp"
        subtitle="Chúng tôi liên tục cải thiện giải pháp để đáp ứng nhu cầu của khách hàng"
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature gray />
      {/* <Price /> */}
      {/* <Client gray /> */}
    </>
  );
};

export default SpeechSolution;
