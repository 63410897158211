import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";
const ImageSolution = () => {
  return (
    <>
      <Helmet>
        <title>Xử lý hình ảnh</title>
        <meta
          name="description"
          content="Nhận dạng các danh lam thắng cảnh, smartcrop, smarttagging"
        />
      </Helmet>
      <Intro
        gray
        title="Phát triển các giải pháp về xử lý hình ảnh"
        subtitle="Chúng tôi giúp khách hàng giải quyết các bài toán liên quan đến thị giác máy tính như phân loại chất lượng sản phẩm bằng hình ảnh, xác định người nổi tiếng, xác định runner trong các giải marathon"
        img={require("../images/dog_cat.jpg").default}
        imgHeight="350px"
      />
      <Hero
        type="image_solution"
        title="Các Giải Pháp"
        subtitle="Chúng tôi liên tục cải thiện giải pháp để đáp ứng nhu cầu của khách hàng"
        filter="invert(21%) sepia(79%) saturate(3672%) hue-rotate(344deg) brightness(91%) contrast(103%)"
      />
      <Feature gray />
      {/* <Price /> */}
      {/* <Client gray /> */}
    </>
  );
};

export default ImageSolution;
