import React from "react";
import {
  IntroContainer,
  IntroInner,
  Heading,
  HeadingTitle,
  HeadingSubtitle,
  Buttons,
  Button,
  SvgImg,
  Left,
  Right,
} from "./Intro.elements";

const Intro = ({ gray, title, subtitle, img, imgHeight }) => {
  return (
    <>
      <IntroContainer gray={gray}>
        <IntroInner>
          <Left>
            <Heading>
              <HeadingTitle>{title}</HeadingTitle>
              <HeadingSubtitle>{subtitle}</HeadingSubtitle>
            </Heading>
            <Buttons>
              <Button to="/">Xem Demo</Button>
              <Button to="/contact">Liên Hệ</Button>
            </Buttons>
          </Left>
          <Right>
            <SvgImg src={img} imgHeight={imgHeight}></SvgImg>
          </Right>
        </IntroInner>
      </IntroContainer>
    </>
  );
};

export default Intro;
