import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTh, FaCaretDown } from "react-icons/fa";

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  /* position: sticky; */
  top: 0;
  z-index: 900;
  /* filter: brightness(0.7) */
  /* border-bottom: 1px solid #f0f0f0; */
`;

export const NavbarInnerContainer = styled.div`
  max-width: 1300px;
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  /* justify-content: space-between; */
  @media screen and (max-width: 960px) {
    justify-content: space-between;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: #000;
`;
export const LogoIcon = styled.div`
  margin: auto 10px;
  font-size: 30px;
`;

export const LogoName = styled.div`
  font-size: 20px;
  text-decoration: none;
  font-weight: 700;
`;

export const MobileIcon = styled.div`
  display: none;
  margin: 0 10px;
  font-size: 20px;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export const NavbarMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 70px;
  @media screen and (max-width: 960px) {
    display: flex;
    position: absolute;
    flex-direction: column;
    height: 90vh;
    width: 100%;
    background: #fff;
    top: 60px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    transition: all 0.5s ease;
    margin-left: 0;
  }
`;
export const NavbarItem = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 2px solid #f4f5f7;
  cursor: pointer;
  margin: 0 10px;
  /* height: 60px; */
  /* color: #000; */
  &:hover {
    border-bottom: 2px solid #42a5f5;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    border-bottom: 0;
    &:hover {
      border-bottom: 0;
      /* color: #ff0000; */
    }
  }
`;

export const NavbarLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #18191f;
  height: 60px;
`;

export const NavbarText = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;
export const NavbarCaretDown = styled(FaCaretDown)``;

export const Button = styled(Link)`
  background: #303667;
  padding: 8px 30px;
  white-space: nowrap;

  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    filter: brightness(0.8);
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
