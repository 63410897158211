import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Price from "../components/Pricev2";
import Client from "../components/Client";
import Intro from "../components/Intro";

const Prices = () => {
  return (
    <>
      <Helmet>
        <title>VOCR - Giá dịch vụ</title>
        <meta
          name="description"
          content="Chất lượng dịch vụ tốt trên thị trường"
        />
      </Helmet>
      <Intro
        gray
        title="Chất lượng dịch vụ tốt cùng sự hỗ trợ 24/7"
        subtitle="Chúng tôi cung cấp cho bạn các lựa chọn phù hợp với quy mô kinh doanh của doanh nghiệp"
        img={require("../images/undraw_server_down_s4lk.svg").default}
        imgHeight="300px"
      />
      <Price />
      <Hero gray />
      <Feature />
      {/* <Client gray /> */}
    </>
  );
};

export default Prices;
