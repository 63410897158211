import React from "react";
import { FaFingerprint, FaBars } from "react-icons/fa";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import {
  FooterContainer,
  FooterInner,
  Column,
  FooterLogo,
  LogoIcon,
  LogoName,
  FooterContact,
  ContactItem,
  Heading,
  FooterItem,
  ContactIcon,
  CopyRight,
} from "./Footer.elements";
const Footer = () => {
  return (
    <FooterContainer>
      <FooterInner>
        <Column>
          <FooterLogo>
            <LogoIcon>
              <FaFingerprint />
            </LogoIcon>
            <LogoName>VOCR</LogoName>
          </FooterLogo>
          <FooterContact>
            <ContactItem>
              <ContactIcon>
                <AiOutlinePhone />
              </ContactIcon>
              0378.050.314 
            </ContactItem>
            <ContactItem>
              <ContactIcon>
                <AiOutlineMail />
              </ContactIcon>
              contact@vocr.vn
            </ContactItem>
          </FooterContact>
          <CopyRight>
            © {new Date().getFullYear()} VOCR. All rights reserved.
          </CopyRight>
        </Column>
        <Column>
          <Heading>Sản phẩm</Heading>
          <FooterItem to="/nic">Nhận dạng giấy tờ tùy thân</FooterItem>
          <FooterItem to="/viocr">Nhận dạng tiếng việt</FooterItem>
          <FooterItem to="/face">So sánh khuôn mặt</FooterItem>
        </Column>
        <Column>
          <Heading>Giải pháp</Heading>
          <FooterItem to="/digitalize_solution">
            Số hóa văn bản phức tạp
          </FooterItem>
          <FooterItem to="/image_solution">Xử lý hình ảnh</FooterItem>
          <FooterItem to="/nlp_solution">Xử lý ngôn ngữ tự nhiên</FooterItem>
          <FooterItem to="/speech_solution">Nhận dạng giọng nói</FooterItem>
        </Column>
        <Column>
          <Heading>Tài liệu</Heading>
          <FooterItem to="/integrate">Tài liệu API</FooterItem>
        </Column>
        <Column>
          <Heading>Về chúng tôi</Heading>
          <FooterItem to="/contact">Liên hệ</FooterItem>
        </Column>
      </FooterInner>
    </FooterContainer>
  );
};

export default Footer;
