import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
`;
export const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 600px; */
`;
export const Row = styled.div`
  display: flex;
  /* margin: 10px 0; */
  margin: 10px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const PersonalInput = styled.input`
  height: 40px;
  width: 300px;
  border: 1px solid #f0f0f0;
  /* margin: 0 10px; */
  padding-left: 10px;
  outline: none;
  transition: all 0.1s;
  font-size: 15px;

  &:hover {
    border: 1px solid #42a5f5;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const MessageInput = styled.textarea`
  height: 200px;
  border: 1px solid #f0f0f0;
  padding-left: 10px;
  padding-top: 10px;
  margin: 0 10px;
  font-size: 15px;
  outline: none;
  transition: all 0.1s;
  width: 100%;
  &:hover {
    border: 1px solid #42a5f5;
  }
`;

export const SubmitBtn = styled.input`
  margin-top: 20px;
  width: 150px;
  height: 40px;
  background: #303667;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;

  /* font-size: 15px; */
  font-weight: 700;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const FormHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
`;
export const FormTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
`;
export const FormSubtitle = styled.div`
  text-align: center;
`;

export const FormHotline = styled.div`
  color: #ff0000;
  margin-top: 10px;
  /* font-weight: 700; */
`;
