import styled from "styled-components";
import { Link } from "react-router-dom";
export const IntroContainer = styled.div`
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
  display: flex;
  justify-content: center;
`;
export const IntroInner = styled.div`
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 100px 0;
  width: 100%;
`;
export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  /* margin-left: 20px; */
`;
export const HeadingTitle = styled.div`
  max-width: 600px;
  font-weight: 700;
  font-size: 40px;
`;
export const HeadingSubtitle = styled.div`
  margin-top: 30px;
  max-width: 500px;
  line-height: 30px;
  font-size: 20px;
`;
export const Buttons = styled.div`
  margin-top: 30px;
`;
export const Button = styled(Link)`
  padding: 10px 30px;
  margin-right: 20px;
  background: #303667;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  &:hover {
    filter: brightness(0.8);
  }
  /* border: none; */
`;

export const SvgImg = styled.img`
  height: ${({ imgHeight }) => imgHeight};
  
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const Right = styled.div``;
