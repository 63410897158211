import styled from "styled-components";
import { Link } from "react-router-dom";
export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
`;
export const PriceInner = styled.div`
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PriceHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const PriceTitle = styled.div`
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 15px;
  font-weight: 700;
`;
export const PriceSubtitle = styled.div``;
export const PriceWrap = styled.div`
  display: flex;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const PriceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eeeff4;
  border-radius: 5px;
  width: 250px;
  padding: 50px 10px;
  margin: 50px 20px 100px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
  } */

  &:nth-child(2) {
    transform: scale(1.05);
  }

  @media screen and (max-width: 960px) {
    margin: 50px 0 10px;
  }
`;

export const Icon = styled.div``;
export const PricePack = styled.div`
  /* font-size: 15px; */
  margin: 20px 0;
  font-weight: 500;
`;
export const PriceValue = styled.div`
  /* font-size: 15px; */
  font-weight: 700;
`;
export const PriceUnit = styled.div``;
export const PriceFeatures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
export const Feature = styled.div`
  margin-top: 10px;
`;

export const PriceBtn = styled(Link)`
  border: none;
  margin-top: 50px;
  padding: 10px 70px;
  border-radius: 5px;
  background: ${({ outline }) => (outline ? "#fff" : "#303667")};
  border: 1px solid #303667;
  /* color:  */
  color: ${({ outline }) => (outline ? "#303667" : "#fff")};

  font-weight: 500;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    /* filter: brightness(1.3); */
    background: ${({ outline }) => (outline ? "#F4F5F7" : "#4B597C")};
  }
`;
