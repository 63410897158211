import styled from "styled-components";
import { Link } from "react-router-dom";
export const BoardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  padding-bottom: 100px;
`;
export const BoardInner = styled.div`
  display: flex;
  max-width: 1300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 10px 20px; */
`;

export const BoardTitle = styled.h2`
  font-size: 40px;
  font-weight: 700;
  margin: 70px 0;
  text-align: center;
  @media screen and (max-width: 960px) {
    max-width: 400px;
  }
`;
export const DemoContainer = styled.div`
  width: 1200px;
  background: #fff;
  border-radius: 5px;
  margin: 20px 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const BoardMobileNote = styled.div`
  display: none;
  font-style: italic;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;
export const DemoInner = styled.div`
  padding: 5px 20px;
`;
export const DemoHeading = styled.div`
  display: flex;
  margin: 0px 10px;
  border-bottom: 1px solid #f0f0f0;
`;
export const DemoItem = styled(Link)`
  padding: 15px 10px;
  text-decoration: none;
  border-bottom: ${({ active }) => (active ? "2px solid #42a5f5" : null)};
  color: #18191f;

  font-weight: ${({ active }) => (active ? 500 : null)};
  &:hover {
    border-bottom: 2px solid #42a5f5;
  }
  font-size: 15px;
  /* font-weight: 500; */
`;
export const DemoMain = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DemoInput = styled.div`
  width: 50%;
  margin: 10px;
`;
export const DemoInputInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Note = styled.div`
  margin: 10px 0;
  font-size: 15px;
  color: #de1a1a;
`;

export const DemoImageContainer = styled.div`
  height: 400px;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  border: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DemoImage = styled.img`
  max-height: 400px;
  max-width: 100%;
`;
export const DemoThumnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ThumnailImages = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 70px;
`;
export const ThumnailImage = styled.img`
  width: 70px;
  height: 50px;
  border: ${({ active }) => (active ? "2px solid #42a5f5" : "2px solid #fff")};
  border-radius: 2px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    border: 2px solid #42a5f5;
  }
`;
export const UploadBtn = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  /* padding: 10px 20px; */
  background: #d9dbe1;
  /* background: #303667;
  color: #fff; */
  /* font-weight: 700; */
  border-radius: 5px;
  font-size: 15px;

  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const InputFile = styled.input`
  display: none;
`;
export const DemoOutputInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const DemoOuput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 10px;
  /* height: 400px; */
`;

export const Output = styled.div`
  border: 1px solid #f0f0f0;
  height: 400px;
  margin: 10px 0;
  border-radius: 5px;
  overflow: auto;
  white-space: pre;
  font-size: 15px;
`;

export const TextNotify = styled.div`
  margin-left: 10px;
`;
