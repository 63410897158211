import React, { useState } from "react";
import { FaFingerprint, FaBars } from "react-icons/fa";
import Dropdown from "../Dropdown";
import {
  NavbarContainer,
  NavbarInnerContainer,
  LogoContainer,
  LogoIcon,
  LogoName,
  MobileIcon,
  NavbarMenu,
  NavbarItem,
  NavbarLink,
  NavbarText,
  NavbarCaretDown,
  Button,
} from "./Navbar.elements";
const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [isExpand, setExpand] = useState(false);
  const [dropdown_sp, setDropdownSP] = useState(false);
  const onMouseEnterSP = () => {
    setDropdownSP(true);
  };

  const onMouseLeaveSP = () => {
    setDropdownSP(false);
  };

  const [dropdown_gp, setDropdownGP] = useState(false);
  const onMouseEnterGP = () => {
    setDropdownGP(true);
  };

  const onMouseLeaveGP = () => {
    setDropdownGP(false);
  };

  const [clickMobileIcon, setClickMobileIcon] = useState(false);

  const handleClickMobileIcon = (e) => {
    // e.stopPropagation();
    // if (typee.target.className.includes("dropdownItem")) {
    // }

    setClickMobileIcon(!clickMobileIcon);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    if (isExpand) {
      setClickMobileIcon(!clickMobileIcon);
    }
    setExpand(!isExpand);
  };

  return (
    <>
      <NavbarContainer>
        <NavbarInnerContainer>
          <LogoContainer to="/">
            <LogoIcon>
              <FaFingerprint />
            </LogoIcon>
            <LogoName>VOCR</LogoName>
          </LogoContainer>
          <MobileIcon onClick={handleClickMobileIcon}>
            <FaBars />
          </MobileIcon>
          <NavbarMenu click={clickMobileIcon}>
            <NavbarItem onClick={handleClickMobileIcon}>
              <NavbarLink to="/">Trang Chủ</NavbarLink>
            </NavbarItem>
            <NavbarItem
              onMouseEnter={onMouseEnterSP}
              onMouseLeave={onMouseLeaveSP}
              className="dropdown"
              // onClick={handleParentClick}
              onClick={handleDropdownClick}
            >
              <NavbarText>
                Sản Phẩm <NavbarCaretDown />
              </NavbarText>

              {dropdown_sp && <Dropdown type="sp" />}
            </NavbarItem>
            <NavbarItem
              onMouseEnter={onMouseEnterGP}
              onMouseLeave={onMouseLeaveGP}
              className="dropdown"
            >
              <NavbarText>
                Giải Pháp
                <NavbarCaretDown />
              </NavbarText>

              {dropdown_gp && (
                <Dropdown type="gp" onClick={handleDropdownClick} />
              )}
            </NavbarItem>
            <NavbarItem onClick={handleClickMobileIcon}>
              <NavbarLink to="/price">Giá</NavbarLink>
            </NavbarItem>
            <NavbarItem onClick={handleClickMobileIcon}>
              <NavbarLink to="/integrate">Tích Hợp</NavbarLink>
            </NavbarItem>
            <NavbarItem onClick={handleClickMobileIcon}>
              <NavbarLink to="/contact">Liên Hệ</NavbarLink>
            </NavbarItem>
            {/* <Button to="/contact">Liên Hệ</Button> */}
          </NavbarMenu>
        </NavbarInnerContainer>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
