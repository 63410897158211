import styled from "styled-components";

export const FeatureContainer = styled.div`
  background: ${({ gray }) => (gray ? "#f4f5f7" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeatureInner = styled.div`
  max-width: 1300px;
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
`;

export const FeatureLeft = styled.div``;

export const FeatureRight = styled.div`
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const FeatureTitle = styled.h2`
  font-size: 30px;
`;

export const FeatureIcon = styled.div`
  margin-right: 50px;

  background: #c0dae5;
  border-radius: 10px;
  padding: 10px;
`;

export const FeatureHeading = styled.div``;

export const FeatureDesc = styled.div`
  text-align: left;
`;

export const FeatureImg = styled.img`
  margin-left: 100px;
  /* height: 580px; */
`;

export const Features = styled.div`
  margin-top: 70px;
`;

export const FeatureWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 25px 0;
`;

export const FeatureSubtitle = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  /* color: #de1a1a; */
`;
