import React, { useState, useEffect } from "react";
import ReactJson from "react-json-view";
import axios from "axios";
import {
  BoardContainer,
  BoardInner,
  BoardTitle,
  DemoContainer,
  DemoInner,
  DemoHeading,
  DemoItem,
  DemoMain,
  DemoInput,
  DemoInputInner,
  Note,
  DemoImageContainer,
  DemoImage,
  DemoThumnail,
  DemoOuput,
  DemoOutputInner,
  ThumnailImage,
  ThumnailImages,
  UploadBtn,
  InputFile,
  Output,
  BoardMobileNote,
  TextNotify,
} from "./Board.elements";
import Table from "./Table";

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = { headers: null, currentHeaderIdx: 0 };

    this.handleHeaders = this.handleHeaders.bind(this);
    this.updateHeaders = this.updateHeaders.bind(this);
    this.updateReponse = this.updateReponse.bind(this);
    this.updateThumnailIdx = this.updateThumnailIdx.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.getCurrentImage = this.getCurrentImage.bind(this);
    this.getReponse = this.getReponse.bind(this);
    this.postImg = this.postImg.bind(this);
  }

  componentDidMount() {
    this.handleHeaders();
  }

  handleHeaders() {
    axios
      .get("https://services.vocr.vn/services")
      .then((res) => {
        this.setState(
          {
            headers: res.data,
          },
          () => {
            this.updateThumnailIdx(0, 0);
          }
        );
      })
      .catch((error) => {});
  }

  updateHeaders(idx) {
    this.setState(
      (prevState) => {
        return { currentHeaderIdx: idx };
      },
      () => {
        this.updateThumnailIdx(0, idx);
      }
    );
  }

  updateReponse(res, headerIdx, thumnailIdx) {
    this.setState((prevState) => {
      let headers = JSON.parse(JSON.stringify(prevState.headers));

      headers[headerIdx].responses[thumnailIdx] = res;
      return {
        headers: headers,
      };
    });
  }

  updateThumnailIdx(idx, headerIdx) {
    this.setState(
      (prevState) => {
        let headers = JSON.parse(JSON.stringify(prevState.headers));
        headers[headerIdx].thumnailIdx = idx;

        return {
          headers: headers,
        };
      },
      () => {
        if (!this.state.headers[headerIdx].responses[idx])
          this.postImg(headerIdx, idx);
      }
    );
  }

  updateImage(data, headerIdx) {
    this.setState(
      (prevState) => {
        let headers = JSON.parse(JSON.stringify(prevState.headers));
        headers[headerIdx].imgs.push(data);

        return { headers: headers };
      },
      () => {
        this.updateThumnailIdx(
          this.state.headers[headerIdx].imgs.length - 1,
          this.state.currentHeaderIdx
        );
      }
    );
  }

  getCurrentImage() {
    if (this.state.headers) {
      const item = this.state.headers[this.state.currentHeaderIdx];
      const thumnailIdx = item.thumnailIdx;
      return item.imgs[thumnailIdx];
    } else {
      return null;
    }
  }

  getReponse() {
    if (this.state.headers) {
      const item = this.state.headers[this.state.currentHeaderIdx];
      const thumnailIdx = item.thumnailIdx;
      return item.responses[thumnailIdx];
    } else {
      return [];
    }
  }

  postImg(headerIdx, thumnailIdx) {
    const key = this.state.headers[headerIdx].id;
    const data = this.state.headers[headerIdx].imgs[thumnailIdx];

    const formData = new FormData();
    formData.append("id", key);
    formData.append("data", data);

    axios
      .post("https://services.vocr.vn/service/" + key, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.updateReponse(res.data, headerIdx, thumnailIdx);
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status == 500) {
          this.updateReponse(
            { error: "Vui lòng chọn đúng loại ảnh" },
            headerIdx,
            thumnailIdx
          );
        } else if (error.response.status == 429) {
          this.updateReponse(
            { error: "Bạn đã sử dụng hết lượt" },
            headerIdx,
            thumnailIdx
          );
        } else {
          this.updateReponse(
            { error: "Vui lòng liên hệ để kiểm tra" },
            headerIdx,
            thumnailIdx
          );
        }
      });
  }

  render() {
    return (
      <BoardContainer>
        <BoardInner>
          <BoardTitle>Số Hóa Các Loại Văn Bản</BoardTitle>
          <BoardMobileNote>
            Bạn chuyển qua phiên bản desktop để xem demo
          </BoardMobileNote>
          <DemoContainer>
            <DemoInner>
              <DemoHeading>
                {this.state.headers &&
                  this.state.headers.map((item, index) => {
                    return (
                      <DemoItem
                        key={index}
                        active={this.state.currentHeaderIdx === index ? 1 : 0}
                        onClick={() => this.updateHeaders(index)}
                        to="/"
                      >
                        {item.title}
                      </DemoItem>
                    );
                  })}
              </DemoHeading>
              <DemoMain>
                <DemoInput>
                  <DemoInputInner>
                    <Note>Hình ảnh cần chụp rõ ràng và không bị mất 4 góc</Note>
                    <DemoImageContainer>
                      <DemoImage src={this.getCurrentImage()}></DemoImage>
                    </DemoImageContainer>
                    <DemoThumnail>
                      <ThumnailImages>
                        {this.state.headers &&
                          this.state.headers[
                            this.state.currentHeaderIdx
                          ].imgs.map((item, index) => {
                            return (
                              <ThumnailImage
                                key={index}
                                active={
                                  this.state.headers[
                                    this.state.currentHeaderIdx
                                  ].thumnailIdx === index
                                    ? 1
                                    : 0
                                }
                                onClick={() =>
                                  this.updateThumnailIdx(
                                    index,
                                    this.state.currentHeaderIdx
                                  )
                                }
                                src={item}
                              />
                            );
                          })}
                      </ThumnailImages>
                      <UploadBtn>
                        <InputFile
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            let reader = new FileReader();
                            let currentIdx = this.state.currentHeaderIdx;

                            reader.onload = function (ev) {
                              this.updateImage(ev.target.result, currentIdx);
                              event.target.value = null;
                            }.bind(this);

                            reader.readAsDataURL(event.target.files[0]);
                          }}
                        />
                        <span>Tải Ảnh Lên</span>
                      </UploadBtn>
                    </DemoThumnail>
                  </DemoInputInner>
                </DemoInput>
                <DemoOuput>
                  <DemoOutputInner>
                    <Note>
                      Kết quả trả ra giá trị và độ tin cậy của từng trường
                    </Note>
                    <Output>
                      {this.state.headers &&
                        this.getReponse() === undefined && (
                          <TextNotify>Đang xử lý...</TextNotify>
                        )}
                      {this.state.headers &&
                        this.getReponse() &&
                        this.state.headers[this.state.currentHeaderIdx].id ==
                          "rtb" && <Table data={this.getReponse()} />}
                      {this.state.headers &&
                        this.getReponse() &&
                        this.state.headers[this.state.currentHeaderIdx].id !==
                          "rtb" && (
                          <ReactJson
                            src={this.getReponse()}
                            theme="rjv-default"
                            displayDataTypes={false}
                            enableClipboard={false}
                          />
                        )}
                    </Output>
                  </DemoOutputInner>
                </DemoOuput>
              </DemoMain>
            </DemoInner>
          </DemoContainer>
        </BoardInner>
      </BoardContainer>
    );
  }
}

export default Board;
