import { BiSupport } from "react-icons/bi";
import { IoFlashOutline } from "react-icons/io5";
import {
  AiOutlineCloudServer,
  AiOutlineCustomerService,
  AiOutlineDeploymentUnit,
} from "react-icons/ai";

export const Items = [
  {
    icon: <AiOutlineDeploymentUnit />,
    title: "Tích hợp thông qua API",
    desc: "Chỉ cần kết nối ứng dụng của bạn với API là có thể sử dụng được các dịch vụ có sẵn",
  },
  {
    icon: <AiOutlineCloudServer />,
    title: "Cài đặt trên máy chủ của bạn",
    desc: "Tăng tính bảo mật cho các ứng dụng đòi hỏi quyền riêng tư",
  },
  {
    icon: <IoFlashOutline />,
    title: "Tốc độ phản hồi nhanh chóng",
    desc: "Thời gian xử lý nhanh chóng với nhiều GPU cấu hình cao",
  },
  {
    icon: <AiOutlineCustomerService />,
    title: "Hỗ trợ liên tục 24/7",
    desc: "Luôn có đội ngũ hỗ trợ bạn kịp thời khi phát sinh bất cứ vấn đề gì",
  },
];
