import React from "react";
import { Helmet } from "react-helmet";

import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>VOCR - Liên hệ</title>
        <meta
          name="description"
          content="Liên hệ VietOCR để nhận sự hỗ trợ tốt nhất"
        />
      </Helmet>
      <ContactForm />
    </>
  );
};

export default Contact;
