import { FaCcVisa } from "react-icons/fa";

import { FcBusinesswoman } from "react-icons/fc";

import { GoCreditCard } from "react-icons/go";
import {
  AiOutlineCreditCard,
  AiOutlineTable,
  AiOutlineScan,
} from "react-icons/ai";

import { IoIosAddCircleOutline } from "react-icons/io";
import { IoReceiptOutline } from "react-icons/io5";
import { VscVerified } from "react-icons/vsc";

export const Items1 = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Nhận dạng giấy tờ tùy thân",
    desc: "Giảm thiểu thời gian xác định danh tính của khách hàng, cải thiện trải nghiệm người dùng",
    icon2: require("../../images/icon/name.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Rút trích thông tin từ bảng",
    desc: "Giúp trích xuất các báo cáo tài chính, bảng thống kê, dữ liệu được liệt kê dưới dạng bảng",
    icon2: require("../../images/icon/edit-table.svg").default,
  },
  {
    icon: <IoReceiptOutline />,
    title: "Nhận dạng hóa đơn",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/icon/bill.svg").default,
  },
  {
    icon: <VscVerified />,
    title: "So sánh khuôn mặt",
    desc: "Giúp định danh khách hàng, giảm thiếu việc tạo các tài khoản giả mạo",
    icon2: require("../../images/icon/immigration.svg").default,
  },
  {
    icon: <AiOutlineScan />,
    title: "Nhận dạng tiếng việt",
    desc: "Rút trích tiếng việt trong các loại văn bản, giúp xử lý, lưu trữ, và truy vấn nhanh chóng",
    icon2: require("../../images/icon/scan.svg").default,
  },
  {
    icon: <IoIosAddCircleOutline />,
    title: "Các sản phẩm khác",
    desc: "Hỗ trợ khách hàng số hóa các loại văn bản khác nhau tùy thuộc vào nhu cầu sử dụng",
    icon2: require("../../images/icon/add.svg").default,
  },
];

export const ItemsCMND = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Chứng minh nhân dân",
    desc: "Giảm thiểu thời gian xác định danh tính của khách hàng, cải thiện trải nghiệm người dùng",
    icon2: require("../../images/id-card.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Căn cước công nhân",
    desc: "Giúp trích xuất các báo cáo tài chính, bảng thống kê, dữ liệu được liệt kê dưới dạng bảng",
    icon2: require("../../images/credit-card.svg").default,
  },
  {
    icon: <IoReceiptOutline />,
    title: "Giấy phép lái xe",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/driver-license.svg").default,
  },

  {
    icon: <IoReceiptOutline />,
    title: "Hộ chiếu",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/passport.svg").default,
  },

  {
    icon: <IoReceiptOutline />,
    title: "Thẻ ngân hàng",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/credit.svg").default,
  },
  {
    icon: <AiOutlineCreditCard />,
    title: "Giấp phép lái xe",
    desc: "Giảm thiểu thời gian xác định danh tính của khách hàng, cải thiện trải nghiệm người dùng",
    icon2: require("../../images/id-card.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Giấy đăng kí xe",
    desc: "Giúp trích xuất các báo cáo tài chính, bảng thống kê, dữ liệu được liệt kê dưới dạng bảng",
    icon2: require("../../images/credit-card.svg").default,
  },
  {
    icon: <IoReceiptOutline />,
    title: "Giấy đăng kiểm xe",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/driver-license.svg").default,
  },
  {
    icon: <IoIosAddCircleOutline />,
    title: "Các loại giấy tờ tùy thân khác",
    desc: "Hỗ trợ khách hàng số hóa các loại văn bản khác nhau tùy thuộc vào nhu cầu sử dụng",
    icon2: require("../../images/credit-card.svg").default,
  },
];

export const FaceSearch = [
  {
    icon: <AiOutlineCreditCard />,
    title: "So sánh khuôn mặt",
    desc: "Giảm thiểu thời gian xác định danh tính của khách hàng, cải thiện trải nghiệm người dùng",
    icon2: require("../../images/id-card.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Tìm kiếm khuôn mặt",
    desc: "Giúp trích xuất các báo cáo tài chính, bảng thống kê, dữ liệu được liệt kê dưới dạng bảng",
    icon2: require("../../images/credit-card.svg").default,
  },
  {
    icon: <IoReceiptOutline />,
    title: "Xác thực thông tin định danh",
    desc: "Rút trích thông tin về giá cả của các sản phẩm giao dich, quản lý đầu ra/vào trên mỗi hóa đơn",
    icon2: require("../../images/driver-license.svg").default,
  },
  {
    icon: <IoIosAddCircleOutline />,
    title: "Các sản phẩm khác",
    desc: "Hỗ trợ khách hàng số hóa các loại văn bản khác nhau tùy thuộc vào nhu cầu sử dụng",
    icon2: require("../../images/credit-card.svg").default,
  },
];

export const ViOCR = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Nhận dạng tiếng việt",
    desc: "Giảm thiểu thời gian xác định danh tính của khách hàng, cải thiện trải nghiệm người dùng",
    icon2: require("../../images/id-card.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Lưu trữ và tìm kiếm văn bản",
    desc: "Giúp trích xuất các báo cáo tài chính, bảng thống kê, dữ liệu được liệt kê dưới dạng bảng",
    icon2: require("../../images/credit-card.svg").default,
  },
];

export const NLPSolution = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Dịch máy cho Tiếng Việt",
    desc: "Phát triển các giải pháp dịch ngôn ngữ, cho phép chuyển đổi lưu trữ và tìm kiếm bằng nhiều ngôn ngữ khác nhau",
    icon2: require("../../images/translator.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Phân loại văn bản",
    desc: "Phân loại các văn bản khác nhau như nghị định, nghị quyết, công văn. Giúp lưu trữ, sắp sếp thuận lợi cho quá trình tìm kiếm",
    icon2: require("../../images/document.svg").default,
  },
];

export const ImageSolution = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Phân loại địa điểm",
    desc: "Xác định địa điểm, từ đó giúp truy vấn các thông tin liên quan đến địa điểm đó",
    icon2: require("../../images/mecca.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Gắn thẻ hình ảnh",
    desc: "Phân lọai ảnh thành nhiều tag khác nhau, giúp thuận tiện trong việc tìm kiếm",
    icon2: require("../../images/tag.svg").default,
  },

  {
    icon: <AiOutlineTable />,
    title: "Các giải pháp khác",
    desc: "Chúng tôi cung cấp các giải pháp khác liên quan đến xử lý hình ảnh như theo dõi đối tượng, kiểm định chất lượng sản phẩm",
    icon2: require("../../images/credit-card.svg").default,
  },
];

export const DigitalizeSolution = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Số hóa các văn bản phức tạp",
    desc: "Chúng tôi cung cấp giải pháp để số hóa các văn bản có cấu trúc phức tạp với độ chính xác cao",
    icon2: require("../../images/barcode-scanner.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Lưu trữ và tìm kiếm văn bản",
    desc: "Xây dựng hệ thống giúp lưu trữ và tìm kiếm các văn bản đã được số hóa",
    icon2: require("../../images/search.svg").default,
  },
];

export const SpeechSolution = [
  {
    icon: <AiOutlineCreditCard />,
    title: "Nhận dạng giọng nói",
    desc: "Cung cấp các giải pháp cho nhận dạng giọng nói, giúp điều khiển các hệ thống bằng giọng nói",
    icon2: require("../../images/voice-message.svg").default,
  },
  {
    icon: <AiOutlineTable />,
    title: "Tổng hợp tiếng nói",
    desc: "Được sử dụng trong các hệ thống hỗ trợ khách hàng, đọc báo tự động",
    icon2: require("../../images/credit-card.svg").default,
  },
];

export const Items = {
  allproducts: Items1,
  cmnd: ItemsCMND,
  face: FaceSearch,
  ocr: ViOCR,
  nlp_solution: NLPSolution,
  image_solution: ImageSolution,
  speech_solution: SpeechSolution,
  digitalize_solution: DigitalizeSolution,
};
